import * as React from "react"
import { StaticQuery, graphql } from 'gatsby'

import Layout from "../components/layout/layout"
import Heading1 from "../components/heading1/heading1"
import Pubcard from "../components/pubcard/pubcard"
import TagFilter from "../components/tag_filter/tag_filter"

const pubsQuery = graphql`
query {
  allStrapiPub {
    edges {
      node {
        ...pubFields
      }
    }
  }
  allStrapiPubTag {
    edges {
      node {
        ...pubTagFields
      }
    }
  }
}
`

const DolnaListaPage = () => {
  const [filter, setFilter] = React.useState(null);

  return (
    <Layout>
      <div className="container mx-auto mt-20 lg:max-w-screen-lg">
        <Heading1 className="mb-32">Долна листа</Heading1>
        <div className="flex flex-wrap gap-6 mb-32 text-xl">
          <div className="flex flex-wrap content-center flex-grow w-full lg:w-auto lg:flex-grow-0">
            <span>Долен филтър:</span>
          </div>
          <StaticQuery
            query={pubsQuery}
            render={data => (
              <>
                {data.allStrapiPubTag.edges.map(tag => (
                  <TagFilter
                    currentFilter={filter}
                    updateFilter={setFilter}
                    key={tag.node.Slug}
                    tag={tag.node}
                  />
                ))}
              </>
            )}
          />
          <button onClick={() => setFilter(null)}>
            <span className="uppercase color-red">Не подбирам</span>
          </button>
        </div>
        <StaticQuery
          query={pubsQuery}
          render={data => (
            <>
              {data.allStrapiPub.edges.map(pub => (
                <Pubcard currentFilter={filter} key={pub.node.id} pub={pub.node} />
              ))}
            </>
          )}
        />
      </div>
    </Layout>
  )
}

export default DolnaListaPage
